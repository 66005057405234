import React, {Component} from 'react';

class Footer extends Component{
	
	render(){
		return(		
            <div className="container">
                <div className="row">
                    <div className="col s6 m4">
                    <img className="materialize-logo" src="/assets/images/tec2web.png" alt="Tec2Web" />
                    <p>Soluções ideais para seu negócio!</p>
                    </div>
                    <div className="col s6 m3">
                    <h5>Sobre nós</h5>
                    <ul>
                        <li><a href="#!">Blog</a></li>
                        <li><a href="#!">Preços</a></li>
                        <li><a href="#!">Documentação</a></li>
                    </ul>
                    </div>
                    <div className="col s6 m3">
                    <h5>Fale Conosco</h5>
                    <ul>
                        <li><a href="#!">suporte@tec2web.com.br</a></li>
                        <li><a href="#!">(11) 97243-7198</a></li>
                    </ul>
                    </div>
                </div>

            </div>								        
		);
      
		
	}
}

export default Footer;
