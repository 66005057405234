import React, {Component} from 'react';
import store              from '../store';
import {getSisStatus, getSisTipoVenda, getSisUnidades}     from '../services/Auth.js';
import FormEdit           from '../components/FormEdit.js';

import Grid             from '../components/Grid.js';
import Paginacao        from '../components/Paginacao.js';

import { GetProdutosAutocompleteAPI } from '../models/ModelProdutos.js';
import { GetServicosAutocompleteAPI } from '../models/ModelServicos.js';
import { GetComandaAPI, PostComandaAPI, DelVendaItemServ, DelVendaItemProd } from '../models/ModelComandas.js';
import { GetFornecedoresAutocompleteAPI } from '../models/ModelFornecedores.js';
import { GetClientesAutocompleteAPI } from '../models/ModelClientes.js';

class ComandasUpdate extends Component{
    
  constructor(props) {
    super(props);
    this.state = store.getState().comandas.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    
    document.body.appendChild(script);    

  }
  
  componentDidUpdate(prevProps, prevState){
  
    if(this.state.refresh===1){
       this.callApi();
       this.setState({
        refresh   : 0
      })  
    } 
    
    var response = {}
    if(store.getState().comandas.cadastro.refresh===2 && 
    store.getState().comandas.cadastro.id!==store.getState().comandas.cadastro.id_venda
    ){
      response = {type: 'COMANDAS.TOOGLE_REFRESH'}
      store.dispatch(response);
      this.callApiGetComanda(store.getState().comandas.cadastro.id)      
    }

    if(store.getState().comandas.cadastro.refresh===3){
      response = {refresh:0, type: 'COMANDAS.TOOGLE_CLEAN'}
      store.dispatch(response);
      var cadastro = store.getState().comandas.cadastro;
      this.setState(cadastro) ; 
    }

  }

  async callApi(){

    let data =
    {
      id_venda:             this.state.id_venda,
      id_cliente:           this.state.id_cliente,
      venda_vlr:            this.state.venda_vlr,
      venda_vlr_desconto:   this.state.venda_vlr_desconto,
      venda_qtdd:           this.state.venda_qtdd,
      id_tipo:              this.state.id_tipo,
      id_produto:           this.state.id_produto,
      id_servico:           this.state.id_servico,
      id_produto_unidade:   this.state.id_produto_unidade,
      id_fornecedor:        this.state.id_fornecedor
    }
      
    var response = await PostComandaAPI(this.state.id_venda,data);
    
    if(this.state.id_venda===0 && response.erro===0){ 
      response.type   = 'COMANDAS.TOOGLE_SET_ID';
      store.dispatch(response);
      response = store.getState().comandas.cadastro;
      this.setState(response); 
      this.callApiGetComanda(this.state.id_venda);
    }
  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  async callApiGetComanda(id){
    
    var response = await GetComandaAPI(id);
  
    response.onClickModalProd       = this.clickChildEditProd;
    response.onClickModalServ       = this.clickChildEditServ;
        
    response.refresh  = 0;     
    if(response.erro===0) 
      response.type   = 'COMANDAS.TOOGLE_GET_ID';
    else
      response.type   = 'COMANDAS.TOOGLE_CLEAN';
    store.dispatch(response);
    var cadastro = store.getState().comandas.cadastro;
    this.setState(cadastro);  
    
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 1
    }) ;
    
  }
 
  handleInputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleInputChangeTipo = (event) =>{
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async clickChildEditProd (event,id) {
    
    var response = await DelVendaItemProd(store.getState().comandas.cadastro.id_venda,id);
    if(response.erro===0)
      alert('Registro deletado com sucesso!');
    
  }

  async clickChildEditServ (event,id) {
    
    var response = await DelVendaItemServ(store.getState().comandas.cadastro.id_venda,id);
    if(response.erro===0)
      alert('Registro deletado com sucesso!');
      
  }

  async clickChildEditServDel (id_venda,id) {
    

    
  }

  handleInputChangeFornecedor = (values) =>{
    var response = {} ;
    response.type   = 'COMANDAS.TOOGLE_SET_ID_FORNECEDOR';
    if(values===null){
      this.setState({
        id_fornecedor: '',
        nome_fornecedor: ''
      });
      response.id_fornecedor = '';
    }else{
      this.setState({
        id_fornecedor: values.id,
        nome_fornecedor: values.nome
      });
      response.id_fornecedor = values.id;
    }
    store.dispatch(response);
      
  }

  handleInputChangeServico = (values) =>{
    if(values===null)
      this.setState({
        id_servico: '',
        nome_servico: ''
      });
    else
      this.setState({
        id_servico: values.id,
        nome_servico: values.nome
      });
      
  }


  handleInputChangeProduto = (values) =>{
    if(values===null)
      this.setState({
        id_produto: '',
        nome_produto: ''
      });
    else
      this.setState({
        id_produto: values.id,
        nome_produto: values.nome
      });
      
  }

  handleInputChangeCliente = (values) =>{
    if(values===null)
      this.setState({
        id_cliente: '',
        cli_razao: ''
      });
    else
      this.setState({
        id_cliente: values.id,
        cli_razao: values.nome
      });
      
  }

  async loadDataFornecedor (nome) {
    var response = await GetFornecedoresAutocompleteAPI(nome);
    if(response.erro===0)
      return response.lista;
    else
      return [{}];
            
  }

  async loadDataServico (nome) {
    
    var response = await GetServicosAutocompleteAPI(nome);
    if(response.erro===0)
      return response.lista;
    else
      return [{}];
        
  }

  async loadDataProduto (nome) {
    var response = await GetProdutosAutocompleteAPI(store.getState().comandas.cadastro.id_fornecedor,nome);
    if(response.erro===0)
      return response.lista;
    else
      return [{}];
            
  }

  async loadDataCliente (nome) {
    var response = await GetClientesAutocompleteAPI(nome);
    if(response.erro===0)
      return response.lista;
    else
      return [{}];
            
  }
  
  render(){  
  	return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

        {<FormEdit  id='id_cliente'           
                    label='Cliente'                                
                    styleDiv='2' 
                    typeField='autocomplete'
                    onChange={this.handleInputChangeCliente} 
                    loadData={this.loadDataCliente}
                    options={[(this.state.id_cliente!=='')?{id:this.state.id_cliente,nome:this.state.cli_razao}:{id:0,nome:''}]}
                    value={(this.state.id_cliente!=='')?{id:this.state.id_cliente,nome:this.state.cli_razao}:{id:0,nome:''}}
                    
                    required='on'
        />}

        {(this.state.id_venda!=0)?<FormEdit  id='venda_vlr_total'           
                    label='Valor total'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='readonly'
                    value={this.state.venda_vlr_total}
                    required='on'
        />:''}

        {(this.state.id_venda!=0)?<FormEdit  id='venda_vlr_prod'           
                    label='Valor dos produtos'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='readonly'
                    value={this.state.venda_vlr_prod}
                    required='on'
        />:''}

        {(this.state.id_venda!=0)?<FormEdit  id='venda_vlr_serv'           
                    label='Valor dos serviços'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='readonly'
                    value={this.state.venda_vlr_serv}
                    required='on'
        />:''}

        {<FormEdit  id='id_tipo'           
                    label='Tipo de venda'                
                    styleDiv='2' 
                    onChange={this.handleInputChangeTipo} 
                    typeField='select'
                    value={this.state.id_tipo}   
                    required='on'
                    optionsField={getSisTipoVenda('')}
        />}

          

        {(this.state.id_tipo==2)?
        <FormEdit  id='nome_fornecedor'           
                    label='Nome Fornecedor'                
                    styleDiv='2' 
                    typeField='autocomplete'
                    onChange={this.handleInputChangeFornecedor} 
                    loadData={this.loadDataFornecedor}
                    options={[(this.state.id_fornecedor!=='')?{id:this.state.id_fornecedor,nome:this.state.nome_fornecedor}:{id:0,nome:''}]}
                    value={(this.state.id_fornecedor!=='')?{id:this.state.id_fornecedor,nome:this.state.nome_fornecedor}:{id:0,nome:''}}                    
                    required='on'
        />:''}

        {(this.state.id_tipo==2)?
        <FormEdit  id='id_produto'           
                    label='Produto'                
                    styleDiv='2' 
                    typeField='autocomplete'
                    onChange={this.handleInputChangeProduto} 
                    loadData={this.loadDataProduto}
                    options={[(this.state.id_produto!=='')?{id:this.state.id_produto,nome:this.state.nome_produto}:{id:0,nome:''}]}
                    value={(this.state.id_produto!=='')?{id:this.state.id_produto,nome:this.state.nome_produto}:{id:0,nome:''}}
                    required='on'
        />:''}

        {(this.state.id_tipo==2)?
        <FormEdit  id='id_produto_unidade'           
                    label='Unidade'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='select'
                    value={this.state.id_produto_unidade}   
                    required='on'
                    optionsField={getSisUnidades()}
        />:''}

        {(this.state.id_tipo==1)?
        <FormEdit  id='id_servico'           
                    label='Serviço'                
                    styleDiv='2' 
                    typeField='autocomplete'
                    onChange={this.handleInputChangeServico} 
                    loadData={this.loadDataServico}
                    options={[(this.state.id_servico!=='')?{id:this.state.id_servico,nome:this.state.nome_servico}:{id:0,nome:''}]}
                    value={(this.state.id_servico!=='')?{id:this.state.id_servico,nome:this.state.nome_servico}:{id:0,nome:''}}
                    required='on'
        />:''}
      
        {<FormEdit  id='venda_qtdd'
                    label='Quantidade'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.venda_qtdd}
                    required='on'
        />}

        {<FormEdit  id='venda_vlr'
                    label='Valor do Item'                
                    styleDiv='2' 
                    mask="real"
                    onChange={this.handleInputChange} 
                    value={this.state.venda_vlr}
                    required='on'
        />}

        {<FormEdit  id='venda_vlr_desconto'
                    label='Valor do desconto'                
                    styleDiv='2' 
                    mask="real"
                    onChange={this.handleInputChange} 
                    value={this.state.venda_vlr_desconto}
        />}

        {<FormEdit  id         = 'gravar'  
                    label      = 'Gravar'         
                    styleDiv   = 'submit right'
                    styleField = 'submit'
                    typeField  = 'submit'   
                    icon       = 'gravar'         
        />}

        {(store.getState().comandas.cadastro.servicos.lista.length>0)?
        Grid(store.getState().comandas.cadastro.servicos)
        :''}

        {(store.getState().comandas.cadastro.produtos.lista.length>0)?
        Grid(store.getState().comandas.cadastro.produtos)
        :''}
      </form>                          


    );
      		
	}
}


export default ComandasUpdate;
