import {combineReducers} from 'redux';

import clientes         from './clientes.js'
import fornecedores     from './fornecedores.js'
import produtos         from './produtos.js'
import servicos         from './servicos.js'
import contasPagar      from './contasPagar.js'
import colaboradores    from './colaboradores.js'
import comandas         from './comandas.js'

export default combineReducers({
    clientes, fornecedores, produtos, servicos, colaboradores, comandas, contasPagar
})