import React, {Component} from 'react';
import {getSisTipoEnd, getSisEstados} from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetFornecedorEndAPI, PostFornecedorEndAPI } from '../models/ModelFornecedoresEnd.js';
import { GetEnderecosAPI } from '../models/ModelEnderecos.js';


class FornecedoresEnderecoUpdate extends Component{
    
  constructor(props) {
    super(props);
    this.state = {
      refresh          : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
      tipo_end         : getSisTipoEnd(),
      estados          : getSisEstados(),
      cidades          : null,
      id_fornecedor       : this.props.id_fornecedor,
      id_fornecedor_end   : this.props.id_fornecedor_end,
      sid_fornecedor_end  : '',
      sid_sis_tp_end   : '',
      sforn_endereco    : '',
      sforn_numero      : '',
      sforn_complemento : '',
      sforn_bairro      : '',
      sforn_cep         : '',
      sid_pais         : '',
      sid_estado       : '',
      sid_cidade       : '',
      sforn_principal   : ''    
    }

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;
    
    document.body.appendChild(script);    
 
  }
  
	componentDidMount() {  
    if(this.state.id_fornecedor>0 && this.state.id_fornecedor_end>0){
      this.callApiGetFornecedorEnd(this.state.id_fornecedor,this.state.id_fornecedor_end)
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    
    if(this.state.refresh===1){
       this.callApi();
       this.setState({
        refresh   : 0
      })  
    } 

    if(this.props.refresh===2 && this.props.id_fornecedor_end!==this.state.id_fornecedor_end){
      this.setState({
        refresh:        this.props.refresh,
        id_fornecedor:     this.props.id_fornecedor,
        id_fornecedor_end: this.props.id_fornecedor_end
      })
      this.callApiGetFornecedorEnd(this.props.id_fornecedor, this.props.id_fornecedor_end)      
    }

    if(this.props.refresh===3 && this.state.id_fornecedor_end>0){
      this.setState({
        refresh:    0,
        id_fornecedor_end: 0,
        sid_fornecedor_end  : '',
        sid_sis_tp_end   : '',
        sforn_endereco    : '',
        sforn_numero      : '',
        sforn_complemento : '',
        sforn_bairro      : '',
        sforn_cep         : '',
        sid_pais         : '',
        sid_estado       : '',
        sid_cidade       : '',
        sforn_principal   : ''    
      
      })
    }
    
  }

  async callApi(){
    let data =
    {
      id_fornecedor_end:  this.state.sid_fornecedor_end,
      id_sis_tp_end:   this.state.sid_sis_tp_end,
      forn_endereco:    this.state.sforn_endereco,
      forn_numero:      this.state.sforn_numero,
      forn_complemento: this.state.sforn_complemento,
      forn_bairro:      this.state.sforn_bairro,
      forn_cep:         this.state.sforn_cep,
      id_pais:         this.state.sid_pais,
      id_estado:       this.state.sid_estado,
      id_cidade:       this.state.sid_cidade,
      forn_principal:   this.state.sforn_principal
    }

    var response = await PostFornecedorEndAPI(this.state.id_fornecedor,this.state.id_fornecedor_end,data);
    if(response.erro===0){
      this.props.returnComponentRefresh();
    }
  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  async callApiGetFornecedorEnd(id_fornecedor,id_fornecedor_end){
    
    var response = await GetFornecedorEndAPI(id_fornecedor,id_fornecedor_end);
    if(response.sid_estado>0) {
      var responseCid = await GetEnderecosAPI(response.sid_estado);
      response.cidades = responseCid.cidades;
    }else{
      response.cidades = null;      
    }
    this.setState(
        response
    );
    
    
  }

  async callApiGetCidades(id_estado){
    
    if(id_estado>0){
      var response = await GetEnderecosAPI(id_estado,true);
      this.setState(
          response
      );
      this.setState({
        sid_cidade : null
      }
    );

    }else{
      this.setState(
        {cidades: null}
      );
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.callApi()
    
  }


  handleCancel = (event) => {
    event.preventDefault()
    this.props.returnComponentRefresh();
    
  }
 
  handleInputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });

  }

  handleInputEstado = (event) =>{
    this.handleInputChange(event)
    this.callApiGetCidades(event.target.value)
  }

	render(){  
    
    return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_fornecedor_end' typeField='hidden' 
                   value={this.state.id_fornecedor_end} 
        />}
        
        {<FormEdit  id='sid_sis_tp_end'         
                    label='Tipo de Endereço'                  
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='select'
                    value={this.state.sid_sis_tp_end}   
                    required='on'
                    optionsField={this.state.tipo_end}
        />}
                
        {<FormEdit  id='sforn_cep'           
                    label='CEP'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_cep}
                    mask="cep"
                    required='on'
        />}

        {<FormEdit  id='sforn_endereco'           
                    label='Endereço'                
                    styleDiv='1' 
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_endereco}
                    mask="numero"
                    required='on'
        />}

        {<FormEdit  id='sforn_numero'           
                    label='Número'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_numero}
                    mask="numero"
                    //required='on'
        />}

        {<FormEdit  id='sforn_complemento'           
                    label='Complemento'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_complemento}
                    mask="numero"
        />}

        {<FormEdit  id='sforn_bairro'           
                    label='Bairro'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_bairro}
                    mask="numero"
                    required='on'
        />}
    
        {<FormEdit  id='sid_estado'         
                    label='Estado'                  
                    styleDiv='2' 
                    onChange={this.handleInputEstado} 
                    typeField='select'
                    value={this.state.sid_estado}   
                    required='on'
                    optionsField={this.state.estados}
        />}
        
        {<FormEdit  id='sid_cidade'         
                    label='Cidade'                  
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='select'
                    value={this.state.sid_cidade}   
                    required='on'
                    optionsField={this.state.cidades}
        />}

        {<FormEdit  id='sforn_principal'           
                    label='Principal?'                
                    styleDiv='2' 
                    typeField='checkbox'
                    onChange={this.handleInputChange} 
                    value={this.state.sforn_principal}
        />}


        {<FormEdit  id= 'cancelar'  
                    label      = 'Cancelar'         
                    styleDiv   = 'submit right' 
                    styleField = 'submit'
                    typeField  = 'button'   
                    onClick={this.handleCancel} 
                    icon       = 'cancelar'         
                  />}

        {<FormEdit  id= 'gravar'  
                    label      = 'Gravar'         
                    styleDiv   = 'submit right' 
                    styleField = 'submit'
                    typeField  = 'submit'  
                    icon       = 'gravar'         
                  />}


        
      </form>                          

    );
      		
	}
}

export default FornecedoresEnderecoUpdate;
