import axios from "axios";
import { getToken } from "./Auth";

const api = axios.create({
  baseURL: "https://tec2web.com.br/api"    
  });


//api.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    //api.defaults.headers.post['Access-Control-Allow-Headers'] ='*';
    //config.headers.post['Access-Control-Allow-Credentials'] =true;
    config.headers.Authorization = 'Bearer '+token;
  }
  return config;
});


export default api;