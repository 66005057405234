import React, {Component} from 'react';
import {getMenu, getEmpresa, getUsuario} from './services/Auth.js';

class Header extends Component{
	constructor(props) {

		super(props);
		this.state = {
			usuario: '',
			empresa: '',
			permissoes: '',
			empresa_img: '/assets/images/icon-tec2web.png'
		}


	}

	componentDidUpdate(prevProps, prevState)  {

	}

	itensMenu = (menu,icon,link) => {
			return 	(
				<a className="collapsible-header" href="{link}" onClick={e => e.preventDefault()}>
					{menu}
					<i className={'material-icons '+icon}>{icon+'_left'}</i>
				</a>
			);
	}

	itensSubMenu = (res) => {

		if(res.length>0){
			var listSubItens = res.map((item, key) => 
				
			<li key={item.id_sis_menu}><a href={item.link} className="waves-effect">
			{item.menu}<i className="material-icons">{item.icon}</i>
			</a></li>
		
			);

			return 	(<div className="collapsible-body"><ul>{listSubItens}</ul></div>);
		}else{
			return 	('');
		}
	
	}


	itensMenuFull = () => {
		var vmenu = getMenu();
		if(vmenu!==null){
			return vmenu.map((item, key) => 		
			<li className="bold waves-effect"  key={item.id_sis_menu}>
				{this.itensMenu (item.menu, item.icon,item.link)}
				{this.itensSubMenu (item.itens)}
			</li>
			);
		}else{
			return ('');
		}
	}

	render(){
		return(		
			<div>
				<div className="navbar-fixed">
				  <nav className="navbar white">
					<div className="nav-wrapper"><a href="#!" className="brand-logo grey-text text-darken-4">Usuario: {getUsuario()}</a>
					  <ul id="nav-mobile" className="right">
						<li><a href="#!" data-target="chat-dropdown" className="dropdown-trigger waves-effect"><i className="material-icons">settings</i></a></li>
					  </ul><a href="#!" data-target="sidenav-left" className="sidenav-trigger left"><i className="material-icons black-text">menu</i></a>
					</div>
				  </nav>
				</div>
				<ul id="sidenav-left" className="sidenav sidenav-fixed">
				  <li>
					  <a href="/pages/admin-dashboard" className="logo-container">
					  {getEmpresa()}{this.state.empresa}
					  <img id="logomenu" src={this.state.empresa_img} alt="Logo empresa" className="left mt20 mr20" />
					  </a>
				  </li>
				  <li className="no-padding">
				  	<ul className="collapsible collapsible-accordion">
						{this.itensMenuFull()}
					</ul>
				  </li>
				</ul>

				<div id="dropdown1" className="dropdown-content notifications">
				  <div className="notifications-title">Notificações</div>
				  <div className="card">
					<div className="card-content"><span className="card-title">Joe Smith made a purchase</span>
					  <p>Content</p>
					</div>
					<div className="card-action"><a href="#!">view</a><a href="#!">dismiss</a></div>
				  </div>
				</div>
				<div id="chat-dropdown" className="dropdown-content dropdown-tabbed">
				  <ul className="tabs tabs-fixed-width">
					<li className="tab col s3"><a href="#settings">Configurações</a></li>
					<li className="tab col s3"><a href="#friends" className="active">Friends</a></li>
				  </ul>
				  <div id="settings" className="col s12">
					<div className="settings-group">
					  <div className="setting">Night Mode
						<div className="switch right">
						  <label>
							<input type="checkbox" /><span className="lever"></span>
						  </label>
						</div>
					  </div>
					  <div className="setting">Beta Testing
						<label className="right">
						  <input type="checkbox" /><span></span>
						</label>
					  </div>
					</div>
				  </div>
				  <div id="friends" className="col s12">
					<ul className="collection flush">
					  <li className="collection-item avatar">
						<div className="badged-circle online"><img src="https://cdn.shopify.com/s/files/1/1775/8583/t/1/assets/portrait1.jpg?v=1218798423999129079" alt="avatar" className="circle" /></div>
						<span className="title">Jane Doe</span>
						<p className="truncate">Lo-fi you probably haven't heard of them</p>
					  </li>
					</ul>
				  </div>
				</div>
							
			</div>
		);
      
		
	}
}

export default Header;
