import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Lista de Endereços de fornecedores
 * @param {*} id 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} modal 
 */
export const GetFornecedoresEndAPI = (id, limit, offset,modal) => {
  
  if(modal===1)
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  

  return api.get('/fornecedor/'+id+'/endereco/lista?limit='+limit+'&offset='+offset)
  .then(response => {
    if(modal===1)
       ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
    return {
      lista     : response.data.result.lista, 
      pages     : response.data.result.pages,
      limit     : response.data.result.limit,
      offset    : response.data.result.offset,
      fornecedor: response.data.result.fornecedor,
      erro      : 0
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    if(modal===1)
        ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })

        		
	
}

/* 
 * Cadastro do Endereço do Fornecedor
 * @param {*} id  
 * @param {*} id_endereco
 */
export const GetFornecedorEndAPI = (id_fornecedor,id_fornecedor_end) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/fornecedor/'+id_fornecedor+'/endereco/'+id_fornecedor_end)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));

    return {
      erro: 0,
      id_fornecedor:          response.data.result.id_fornecedor,
      id_fornecedor_end:      response.data.result.id_fornecedor_end,
      refresh:                0,
      sid_sis_tp_end:         response.data.result.id_sis_tp_end,
      sforn_endereco:         response.data.result.forn_endereco,
      sforn_numero:           response.data.result.forn_numero,
      sforn_complemento:      response.data.result.forn_complemento,
      sforn_bairro:           response.data.result.forn_bairro,
      sforn_cep:              response.data.result.forn_cep,
      sid_pais:               response.data.result.id_pais,
      sid_estado:             response.data.result.id_estado,
      sid_cidade:             response.data.result.id_cidade,
      sforn_principal:        response.data.result.forn_principal,
      sid_usuario_cad:        response.data.result.id_usuario_cad,
      sforn_data_cad:         response.data.result.forn_data_cad,
      sid_usuario_upd:        response.data.result.id_usuario_upd,
      sforn_data_upd:         response.data.result.forn_data_upd,
        }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Cadastro do Endereço Fornecedor 
 * @param {*} id  
 */
export const PostFornecedorEndAPI = (id_fornecedor,id_fornecedor_end,data) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     

  var url = '';

  if(id_fornecedor_end===undefined){
    url = '/fornecedor/'+id_fornecedor+'/endereco/add';
  }else{
    url = '/fornecedor/'+id_fornecedor+'/endereco/'+id_fornecedor_end;
  }

  return api.post(url,data)
    .then(response => {
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={response.data.result.mensagem}/>
                              ,document.getElementById('sys-modal'));

      return {
        erro: 0,
        refresh: 0
      }
                        

      })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
          errorCatch = error.response.data.error;

      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={errorCatch}/>
                              ,document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })
  
}
