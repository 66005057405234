import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';
import {getSisCidades, getSisIdEstado,setSisCidades } from '../services/Auth.js';

/**
 * Lista de Clientes
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */
export const GetEnderecosAPI = (id_estado,modal) => {
  
  if(modal)
    ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  
  var id_estado_atual = getSisIdEstado();
  if(id_estado_atual===id_estado){
    return {
      erro        : 0,
      cidades     : getSisCidades()
    }
  }

  return api.get('/endereco/'+id_estado)
  .then(response => {
    setSisCidades(id_estado,response.data.result.cidades)
    
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   

    
    return {
      erro        : 0,
      cidades     : getSisCidades()
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1,
      cidades   : null
    }


  })

        		
	
}
