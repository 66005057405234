import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Lista de Contas A Pagar
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 * @param {*} fDataIni 
 * @param {*} fDataFim 
 * @param {*} fDataTipo 
 */

export const GetContasPagarAPI = (limit, offset, fNome, fDataIni, fDataFim, fDataTipo) => {

  ReactDOM.render(<Modal type="progress" />, document.getElementById('sys-modal'));


  return api.get('/contasapagar/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome +
    '&fdataini=' + fDataIni + '&fdatafim=' + fDataFim + '&fdatatipo=' + fDataTipo)
    .then(response => {
      ReactDOM.render(<Modal type="clear" />, document.getElementById('sys-modal'));
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
        errorCatch = error.response.data.error;
      ReactDOM.render(<Modal type="warning"
        button="Fechar"
        title="Atenção!"
        content={errorCatch}
      />
        , document.getElementById('sys-modal'));
      return {
        error: 1
      }


    })



}


/* 
 * Busca Contas A Pagar
 * @param {*} id  
 */
export const GetContasPagarIdAPI = (id) => {
  ReactDOM.render(<Modal type="progress" />, document.getElementById('sys-modal'));
  return api.get('/contasapagar/' + id)
    .then(response => {
      ReactDOM.render(<Modal type="clear" />, document.getElementById('sys-modal'));
      var ret = response.data.result;
      ret.id = id;
      ret.id_contas_pagar = id;
      ret.refresh = 0;
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
        errorCatch = error.response.data.error;

      ReactDOM.render(<Modal type="warning"
        button="Fechar"
        title="Atenção!"
        content={errorCatch}
      />
        , document.getElementById('sys-modal'));

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro de Contas A Pagar
 * @param {*} id 
 * @param {*} data 
 */
export const PostContasPagarAPI = (id, data) => {
  ReactDOM.render(<Modal type="progress" />, document.getElementById('sys-modal'));

  var url = '';
  if (id === 0) {
    url = '/contasapagar/add';
  } else {
    url = '/contasapagar/' + id;
  }

  return api.post(url, data)
    .then(response => {
      ReactDOM.render(<Modal type="warning"
        button="Fechar"
        title="Atenção!"
        content={response.data.result.mensagem} />
        , document.getElementById('sys-modal'));

      return {
        erro: 0,
        refresh: 0
      }

    })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
        errorCatch = error.response.data.error;
      ReactDOM.render(<Modal type="warning"
        button="Fechar"
        title="Atenção!"
        content={errorCatch} />
        , document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })

}
