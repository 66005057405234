import React, {Component} from 'react';
import store              from '../store';
import {getSisUnidades}     from '../services/Auth.js';
import FormEdit           from '../components/FormEdit.js';

import { GetProdutoAPI, PostProdutoAPI } from '../models/ModelProdutos.js';
import { GetFornecedoresAutocompleteAPI } from '../models/ModelFornecedores.js';

class ProdutosUpdate extends Component{
    
  constructor(props) {
    super(props);
    this.state = store.getState().produtos.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    
    document.body.appendChild(script);    

  }
  
  componentDidUpdate(prevProps, prevState){
  
    if(this.state.refresh===1){
       this.callApi();
       this.setState({
        refresh   : 0
      })  
    } 
    
    var response = {}
    if(store.getState().produtos.cadastro.refresh===2 && 
    store.getState().produtos.cadastro.id!==store.getState().produtos.cadastro.id_produto
    ){
      response = {type: 'PRODUTOS.TOOGLE_REFRESH'}
      store.dispatch(response);
      this.callApiGetProduto(store.getState().produtos.cadastro.id)      
    }

    if(store.getState().produtos.cadastro.refresh===3){
      response = {refresh:0, type: 'PRODUTOS.TOOGLE_CLEAN'}
      store.dispatch(response);
      var cadastro = store.getState().produtos.cadastro;
      this.setState(cadastro) ; 
    }

  }

  async callApi(){

    let data =
    {
      id_fornecedor:        this.state.id_fornecedor,
      id_produto_unidade:   this.state.id_produto_unidade,
      xProd:                this.state.xProd,
      cProd:                this.state.cProd,
      qCom:                 this.state.qCom,
      vUnCom:               this.state.vUnCom
    }
    var response = await PostProdutoAPI(this.state.id_produto,data);

    if(this.state.id_produto===0 && response.erro===0){ 
      response.type   = 'PRODUTOS.TOOGLE_CLEAN';
      store.dispatch(response);
      response = store.getState().produtos.cadastro;
    }

    this.setState(
        response
    );

  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  async callApiGetProduto(id){
    
    var response = await GetProdutoAPI(id);
    
    response.refresh  = 0;     
    if(response.erro===0) 
      response.type   = 'PRODUTOS.TOOGLE_GET_ID';
    else
      response.type   = 'PRODUTOS.TOOGLE_CLEAN';
    store.dispatch(response);
    var cadastro = store.getState().produtos.cadastro;
    this.setState(cadastro);  
    
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 1
    }) ;
    
  }
 
  handleInputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleInputChangeAuto = (values) =>{
    if(values===null)
      this.setState({
        id_fornecedor: '',
        nome_fornecedor: ''
      });
    else
      this.setState({
        id_fornecedor: values.id,
        nome_fornecedor: values.nome
      });
      
  }

  async loadData (nome) {
    var response = await GetFornecedoresAutocompleteAPI(nome);
    if(response.erro===0)
      return response.lista;
    else
      return [{}];
            
  }

  render(){  
  	return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit  id='id_produto' 
                    typeField='hidden' 
                    value={this.state.id_produto} 
        />}

        {<FormEdit  id='id_fornecedor'           
                    label='ID Fornecedor'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.id_fornecedor}
                    typeField='readonly' 
                    required='on'
        />}
       
        {<FormEdit  id='nome_fornecedor'           
                    label='Nome Fornecedor'                
                    styleDiv='2' 
                    typeField='autocomplete'
                    onChange={this.handleInputChangeAuto} 
                    loadData={this.loadData}
                    options={[(this.state.id_fornecedor!=='')?{id:this.state.id_fornecedor,nome:this.state.nome_fornecedor}:{id:0,nome:''}]}
                    value={(this.state.id_fornecedor!=='')?{id:this.state.id_fornecedor,nome:this.state.nome_fornecedor}:{id:0,nome:''}}
                    
                    required='on'
        />}

        {<FormEdit  id='cProd'           
                    label='Cód. Produto'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.cProd}
                    required='on'
        />}

        {<FormEdit  id='xProd'           
                    label='Nome do Produto'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.xProd}
                    required='on'
        />}

        {<FormEdit  id='qCom'        
                    label='Quantidade'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    mask="numero"
                    value={this.state.qCom}        
        />}

        {<FormEdit  id='id_produto_unidade'         
                    label='Unidade'                  
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='select'
                    value={this.state.id_produto_unidade}   
                    required='on'
                    optionsField={getSisUnidades()}
        />}

        {<FormEdit  id='vUnCom'        
                    label='Valor'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    mask="real"
                    value={this.state.vUnCom}        
        />}
        
        {<FormEdit  id         = 'gravar'  
                    label      = 'Gravar'         
                    styleDiv   = 'submit right'
                    styleField = 'submit'
                    typeField  = 'submit'   
                    icon       = 'gravar'         
                  />}
      </form>                          

    );
      		
	}
}


export default ProdutosUpdate;
