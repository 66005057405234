import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Header from '../Header';
import Modal from '../components/Modal.js';

class Home extends Component{
    
		
  //constructor(props) {
  //  super(props);
          


  // }

  componentDidMount(){  
   //ReactDOM.render(<div>teste</div>,document.getElementById('sys-title'));  
   ReactDOM.render(<Header />,document.getElementById('sys-header'));  
   ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
  }


  render(){  
    var x = document.body;
    x.style.backgroundImage = "none"; 
    
   //ReactDOM.render(<Header /> ,document.getElementById('sys-title'));  

    //document.getElementById('sys-header').appendChild('12');
		
    x = document.getElementById('sys-header');
    x.style.display = "block";
		x = document.getElementById('sys-footer');
    x.style.display = "block";
    
  	return (
      <div className="col s12">
        <h2>Home</h2>

      </div>

    );
      		
	}
}

export default Home;
