import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Lista de Vendas
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetComandasAPI = (limit, offset,fNome,fData) => {
  
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  return api.get('/venda/lista?limit='+limit+'&offset='+offset+'&fnome='+fNome+
  '&fdata='+fData)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
    return {
      lista     : response.data.result.lista, 
      pages     : response.data.result.pages,
      limit     : response.data.result.limit,
      offset    : response.data.result.offset,
      erro      : 0
      
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })

        		
	
}

/* 
 * Deleta Item Venda Prod
 * @param {*} id  
 * @param {*} id_venda_prod  
 */
export const DelVendaItemProd = (id,id_venda_prod) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/venda/del/prod/'+id+'/'+id_venda_prod)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var ret         = response.data.result;
    ret.refresh     = 0;
    ret.erro        = 0; 
    return ret
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Deleta Item Venda Serv
 * @param {*} id  
 * @param {*} id_venda_serv  
 */
export const DelVendaItemServ = (id,id_venda_serv) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/venda/del/serv/'+id+'/'+id_venda_serv)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var ret         = response.data.result;
    ret.refresh     = 0;
    ret.erro        = 0; 
    return ret
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Busca Venda
 * @param {*} id  
 */
export const GetComandaAPI = (id) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/venda/'+id)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var ret         = response.data.result;
    ret.id          = id;
    ret.id_venda  = id;
    ret.refresh     = 0;
    ret.erro        = 0; 
    return ret
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Cadastro do Venda
 * @param {*} id 
 * @param {*} data 
 */
export const PostComandaAPI = (id,data) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     

  var url = '';
  if(id===0){
    url = '/venda/add';
  }else{
    url = '/venda/'+id;
  }
  console.log(data);
  return api.post(url,data)
    .then(response => {
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={response.data.result.mensagem}/>
                              ,document.getElementById('sys-modal'));
      
      return {
        erro: 0,
        id_venda: response.data.result.id,
        refresh: 0
      }

      })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
          errorCatch = error.response.data.error;
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={errorCatch}/>
                              ,document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })
  
}
