import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Lista de Colaboradores
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetColaboradoresAPI = (limit, offset,fNome,fid_sis_status) => {
  
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  

  return api.get('/colaborador/lista?limit='+limit+'&offset='+offset+'&fnome='+fNome+
  '&fid_sis_status='+fid_sis_status)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
    return {
      lista     : response.data.result.lista, 
      pages     : response.data.result.pages,
      limit     : response.data.result.limit,
      offset    : response.data.result.offset,
      erro      : 0
      
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })

        		
	
}


/* 
 * Busca Colaborador
 * @param {*} id  
 */
export const GetColaboradorAPI = (id) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/colaborador/'+id)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var ret             = response.data.result;
    ret.id              = id;
    ret.id_colaborador  = id;
    ret.refresh         = 0;
    ret.erro            = 0; 
    return ret
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Cadastro do Colaborador
 * @param {*} id 
 * @param {*} data 
 */
export const PostColaboradorAPI = (id,data) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     

  var url = '';
  if(id===0){
    url = '/colaborador/add';
  }else{
    url = '/colaborador/'+id;
  }
  
  return api.post(url,data)
    .then(response => {
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={response.data.result.mensagem}/>
                              ,document.getElementById('sys-modal'));
      
      return {
        erro: 0,
        refresh: 0
      }

      })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
          errorCatch = error.response.data.error;
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={errorCatch}/>
                              ,document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })
  
}
