import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Lista de Endereços de clientes
 * @param {*} id 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} modal 
 */
export const GetClientesEndAPI = (id, limit, offset,modal) => {
  
  if(modal===1)
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  

  return api.get('/cliente/'+id+'/endereco/lista?limit='+limit+'&offset='+offset)
  .then(response => {
    if(modal===1)
       ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
    return {
      lista     : response.data.result.lista, 
      pages     : response.data.result.pages,
      limit     : response.data.result.limit,
      offset    : response.data.result.offset,
      cliente   : response.data.result.cliente,
      erro      : 0
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    if(modal===1)
        ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })

        		
	
}

/* 
 * Cadastro do Endereço do Cliente
 * @param {*} id  
 * @param {*} id_endereco
 */
export const GetClienteEndAPI = (id_cliente,id_cliente_end) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/cliente/'+id_cliente+'/endereco/'+id_cliente_end)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));

    return {
      erro: 0,
      id_cliente:             response.data.result.id_cliente,
      id_cliente_end:         response.data.result.id_cliente_end,
      refresh:                0,
      sid_sis_tp_end:         response.data.result.id_sis_tp_end,
      scli_endereco:          response.data.result.cli_endereco,
      scli_numero:            response.data.result.cli_numero,
      scli_complemento:       response.data.result.cli_complemento,
      scli_bairro:            response.data.result.cli_bairro,
      scli_cep:               response.data.result.cli_cep,
      sid_pais:               response.data.result.id_pais,
      sid_estado:             response.data.result.id_estado,
      sid_cidade:             response.data.result.id_cidade,
      scli_principal:         response.data.result.cli_principal,
      sid_usuario_cad:        response.data.result.id_usuario_cad,
      scli_data_cad:          response.data.result.cli_data_cad,
      sid_usuario_upd:        response.data.result.id_usuario_upd,
      scli_data_upd:          response.data.result.cli_data_upd,
        }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Cadastro do Endereço Cliente 
 * @param {*} id  
 */
export const PostClienteEndAPI = (id_cliente,id_cliente_end,data) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     

  var url = '';

  if(id_cliente_end===undefined){
    url = '/cliente/'+id_cliente+'/endereco/add';
  }else{
    url = '/cliente/'+id_cliente+'/endereco/'+id_cliente_end;
  }

  return api.post(url,data)
    .then(response => {
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={response.data.result.mensagem}/>
                              ,document.getElementById('sys-modal'));

      return {
        erro: 0,
        refresh: 0
      }
                        

      })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
          errorCatch = error.response.data.error;

      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={errorCatch}/>
                              ,document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })
  
}
