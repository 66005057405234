export const TOKEN_KEY  = "@Tec2Web-Token";
export const MENU       = "@Tec2Web-Menu";
export const EMPRESA    = "@Tec2Web-Empresa";
export const USUARIO    = "@Tec2Web-Usuario";
export const STATUS     = "@Tec2Web-SisStatus";
export const UNIDADES   = "@Tec2Web-SisUnidades";
export const FORMASPGTO = "@Tec2Web-SisFormasPgto";
export const TIPOEND    = "@Tec2Web-TipoEnd";
export const ESTADOS    = "@Tec2Web-Estados";
export const CIDADES    = "@Tec2Web-Cidades";
export const ID_ESTADO  = "@Tec2Web-Id-Estado";


export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const isMenu = () => sessionStorage.getItem(MENU) !== null;
export const getMenu = () => {
  var value = sessionStorage.getItem(MENU);

  value = JSON.parse(value);
  for (var key in value) {
    if(value[key].itens.length>0)
        value[key].itens = JSON.parse(value[key].itens);
  } 


  return value;
}

export const setMenu = (html) => {
    
  for (var key in html) {
      html[key].itens = JSON.stringify(html[key].itens);
  } 
  html = JSON.stringify(html);
  sessionStorage.setItem(MENU, html);

};

export const getSisTipoVenda = (tela) => {
  var result = [];
      result[1] = Object({id:1,value:'Serviço'});
      result[2] = Object({id:2,value:'Produto'});
        
      return result;
  
  } 

export const getSisStatus = (tela) => {
  var value = sessionStorage.getItem(STATUS);
  var result = [];
  var campos = Object;
  value = JSON.parse(value);

  for (var key in value) {
      campos = Object.entries(value[key]);
      for (var i = 0; i < campos.length; i++) {
        if(campos[i][0]===tela && campos[i][1]===1) 
          result[key] = Object({id:value[key].id_sis_status,value:value[key].status})
        
      }
  
  } 
  return result;
}


export const getSisTipoVencimento = (tela) => {
  var result = [];
  result[1] = Object({id:'vencimento',value:'Vencimento'});
  result[2] = Object({id:'cadastro',value:'Cadastro'});
  if(tela='contaspagar'){
    result[3] = Object({id:'pagamento',value:'Pagamento'});
  }else{
    result[3] = Object({id:'pagamento',value:'Recebimento'});
  }
  return result;
}

export const getSisUnidades = () => {
  var value = sessionStorage.getItem(UNIDADES);
  var result = [];
  value = JSON.parse(value);

  for (var key in value) {
          result[key] = Object({id: value[key].id_produto_unidade,value: value[key].prod_unidade})  
  } 
  return result;
}

export const getFormasPgto = (tela) => {
  var value = sessionStorage.getItem(FORMASPGTO);
  var result = [];
  var campos = Object;
  value = JSON.parse(value);
  for (var key in value) {
      campos = Object.entries(value[key]);
      for (var i = 0; i < campos.length; i++) {
        if(campos[i][0]===tela && campos[i][1]===1) 
          result[key] = Object({id:value[key].id_sis_forma_pgto,value:value[key].forma_pgto_nome})        
      }
  
  } 

  return result;
}
  
export const getSisEstados = () => {
  var value = sessionStorage.getItem(ESTADOS);
  var result = [];
  var campos = Object;
  value = JSON.parse(value);
  for (var key in value) {
      campos = Object.entries(value[key]);
      for (var i = 0; i < campos.length; i++) {
          result[key] = Object({id:value[key].id_estado,value:value[key].sigla})
      }
  
  } 
  return result;
}

export const getSisCidades = () => {
  var value = sessionStorage.getItem(CIDADES);
  var result = [];
  var campos = Object;
  value = JSON.parse(value);
  for (var key in value) {
      campos = Object.entries(value[key]);
      for (var i = 0; i < campos.length; i++) {
          result[key] = Object({id:value[key].id_cidade,value:value[key].cidade})
      }
  
  } 
  return result;
}

export const getSisTipoEnd = () => {
  var value = sessionStorage.getItem(TIPOEND);
  var result = [];
  var campos = Object;
  value = JSON.parse(value);

  for (var key in value) {
      campos = Object.entries(value[key]);
      for (var i = 0; i < campos.length; i++) {
          result[key] = Object({id:value[key].id_sis_tp_end,value:value[key].tp_end})        
      }
  
  } 
  return result;
}

export const setSisStatus = (html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(STATUS, html);

};

export const setSisUnidades = (html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(UNIDADES, html);

};

export const setSisFormasPgto = (html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(FORMASPGTO, html);

};

export const setSisTipoEnd = (html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(TIPOEND, html);

};

export const setSisEstados = (html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(ESTADOS, html);

};

export const setSisCidades = (id_estado,html) => {

  html = JSON.stringify(html);
  sessionStorage.setItem(CIDADES, html);
  sessionStorage.setItem(ID_ESTADO, id_estado);

};

export const getEmpresa = () => sessionStorage.getItem(EMPRESA);


export const setEmpresa = str => {
  sessionStorage.setItem(EMPRESA, str);
};

export const getUsuario = () => sessionStorage.getItem(USUARIO);
export const getSisIdEstado = () => sessionStorage.getItem(ID_ESTADO);

export const setUsuario = str => {
  sessionStorage.setItem(USUARIO, str);
};

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(MENU);
  sessionStorage.removeItem(STATUS);
  sessionStorage.removeItem(UNIDADES);
  sessionStorage.removeItem(FORMASPGTO);
  sessionStorage.removeItem(ESTADOS);
  sessionStorage.removeItem(TIPOEND);
  sessionStorage.removeItem(USUARIO);
  sessionStorage.removeItem(EMPRESA);
  
};