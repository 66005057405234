import React, {Component} from 'react';
import ReactDOM         from 'react-dom';
import store          from '../store';

import Grid             from '../components/Grid.js';
import Paginacao        from '../components/Paginacao.js';
import Collection       from '../components/Collection.js';
import FormEdit         from '../components/FormEdit.js';
import NavBar           from '../components/NavBar.js';
import Modal            from '../components/Modal.js';

import {GetClientesAPI} from '../models/ModelClientes.js';

import ClientesUpdate   from './ClientesUpdate.js';
import ClientesEndereco from './ClientesEndereco.js';


class Clientes extends Component{
  constructor(props) {
    super(props);
    this.state = store.getState().clientes.grids;
  }

	componentDidMount() {
    this.callApi()
  }
  
  componentDidUpdate(prevProps, prevState){

    if(this.state.refresh===1){
      this.setState({refresh:2});
      this.callApi()
    }
    
    if(this.state.refresh===4){
      let button = document.querySelector('.endereco');
      button.click();
    }
  }

  callBackPaginacao  = (response)=> {
    response.refresh = 1;
    response.type    = 'CLIENTES.'+response.type;
    store.dispatch(response);
    this.setState(response);   
  }

  handleSubmit = (event) => {
    event.preventDefault()
    
    var response = {
      refresh : 1,      
      fNome   : document.getElementById('fNome').value,
      type    : 'CLIENTES.TOOGLE_FNOME'
    }

    store.dispatch(response);
    this.setState(response);   

  }
  
  novoCadastro = (event) => {
    event.preventDefault()
    var response = {type: 'CLIENTES.TOOGLE_CLEAN', refresh: 3}
    store.dispatch(response);
    
    let button = document.querySelector('.cadastro');
    button.click();
    this.setState({
      refresh: 3
    }) ;
  
  }

  clickChild = (event,id) => {
    var buttons = [
      {item:
        <Collection onClick={store.getState().clientes.grids.edit.onClick} itens={store.getState().clientes.grids.edit.itens} />
      } ]; 
    
    ReactDOM.render (<Modal type    = "warning" 
                            button  = "Fechar" 
                            title   = "O que deseja fazer?" 
                            content = {buttons}
                            />
                            ,document.getElementById('sys-modal'));
                        
    this.setState({
      refresh: 0,
      id:id,
    }) ;
  }

  clickChildCadastro = (event) => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var response = {
        id:       this.state.id,
        type:     'CLIENTES.TOOGLE_CADASTRO'
      }
    
    store.dispatch(response);
        
    let button = document.querySelector('.cadastro');
    button.click();

    this.setState({
      refresh: 5
    }) ;
  }

  clickChildEndereco = (event) => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    let nav = store.getState().clientes.grids.nav;
    nav.disabled[2]=null;
    var response = {refresh: 4, id_cliente: this.state.id, type: 'CLIENTES.ENDERECO.TOOGLE_ID_CLIENTE'}
    store.dispatch(response);

    this.setState(response) ;
    
    response = { endereco: <ClientesEndereco />, nav: nav, type: 'CLIENTES.TOOGLE_NAV_ENDERECO'}
    store.dispatch(response);

  }

  async callApi(){
    var grids = store.getState().clientes.grids;

    var response = await GetClientesAPI(grids.limit,grids.offset,grids.fNome);
    response.refresh = 0;      
    response.onClickModal       = this.clickChild;
    response.callBackPaginacao  = this.callBackPaginacao;
    response.editOnClick       = [this.clickChildCadastro,this.clickChildEndereco];
    response.type               = 'CLIENTES.TOOGLE_LISTA';
    
    store.dispatch(response);
    grids = store.getState().clientes.grids;
    this.setState(grids) ;   
  }



	render(){   

  	return (
       
      <div className="container">
        <div className="row">
          <h2 className="section-title">Clientes</h2>
          <div className="card">
            {<NavBar  className = {store.getState().clientes.grids.nav.className} 
                      tabs      = {store.getState().clientes.grids.nav.tabs} 
                      disabled  = {store.getState().clientes.grids.nav.disabled}
                      onClick   = {store.getState().clientes.grids.nav.onClick}
                      href      = {store.getState().clientes.grids.nav.href}
                      />}
            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
              
                  {<FormEdit  id         = 'fNome' 
                              label      = 'Busque por nome' 
                              styleDiv   = '4' />}
        
                  {<FormEdit  id         = 'submit'  
                              label      = 'Pesquisar'         
                              styleDiv   = 'submit' 
                              styleField = 'submit'
                              typeField  = 'submit'   
                              icon       = 'pesquisa'         
                  />}

                  {<FormEdit  id             = 'cadNovo'  
                                  label      = 'Novo Cadastro'         
                                  styleDiv   = 'submit' 
                                  onClick    = {this.novoCadastro} 
                                  styleField = 'submit'
                                  typeField  = 'button' 
                                  icon       = 'adiciona'                
                      />}

              </form>

              {Grid(store.getState().clientes.grids)}
              {Paginacao(store.getState().clientes.grids)}
              
            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ClientesUpdate />}
            </div>
            <div id="endereco" className="dataTables_wrapper no-footer row">
              {store.getState().clientes.grids.endereco}
            </div>
          </div>
        </div>
      </div>

    );
      		
	}
}
export default (Clientes); 
