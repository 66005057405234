const INITIAL_STATE = {
    grids:  
    {
        header      : ['ID','Produto','Qtdd','Unidade','Valor'],
        colunas     : ['id_produto','xProd','qCom','prod_unidade','vUnCom'],
        checkbox    : '',
        styles      : ['50px','','','',''],
        lista       : [],
  
        limit       : 25,
        offset      : 1,
        pages       : 0,
        fNome       : '',
        endereco    : null,
        nav         :{
          tabs          :['Lista de Produtos','Cadastrar'],
          className     :['lista active','cadastro'],
          disabled      :[null,null],
          href          :['#lista','#cadastro'],
          onClick       :[null,null,null]
        },
        edit        :{
          itens     :['Alterar Cadastro'],
          onClick   :['']
        },
        onClickModal: '',
        id          : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
        refresh     : 0
    },
    cadastro:
    {
        refresh             : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id                  : 0,
        id_produto          : '',
        xProd               : '',
        cProd               : '',
        qCom                : '',
        vUnCom              : '',
        id_fornecedor       : '',
        id_produto_unidade  : '',
        nome_fornecedor     : ''
    }

}

export default function produtos(state = INITIAL_STATE, action){
    var grid            = state.grids;
    if(action.type === 'PRODUTOS.TOOGLE_LISTA'){
        grid.erro           = action.erro;
        if(grid.erro===0){
            grid.lista              = action.lista;
            grid.onClickModal       = action.onClickModal;
            grid.callBackPaginacao  = action.callBackPaginacao;
            grid.pages              = action.pages;
            grid.limit              = action.limit;
            grid.offset             = action.offset;
            grid.edit.onClick       = action.editOnClick;
        }
        return {...state,grid}
    }

    if(action.type === 'PRODUTOS.TOOGLE_FNOME'){
        grid.fNome          = action.fNome;
        return {...state,grid}
    }
    
    if(action.type === 'PRODUTOS.TOOGLE_LIMIT'){
        grid.offset         = 1;
        grid.limit          = action.limit;
        return {...state,grid}
    }
    
    if(action.type === 'PRODUTOS.TOOGLE_OFFSET'){
        grid.offset         = action.offset;
        return {...state,grid}
    }
    
    var cadastro        = state.cadastro;
    if(action.type === 'PRODUTOS.TOOGLE_CADASTRO'){
        cadastro.id         = action.id;
        cadastro.refresh    = 2;
        return {...state,cadastro}
    }
    
    if(action.type === 'PRODUTOS.TOOGLE_REFRESH'){
        cadastro.refresh    = 0;
        return {...state,cadastro}
    }

    if(action.type === 'PRODUTOS.TOOGLE_GET_ID'){
        cadastro.refresh                = 0;
        cadastro.id_produto             = action.id_produto;
        cadastro.cProd                  = action.cProd;
        cadastro.xProd                  = action.xProd;
        cadastro.vUnCom                 = action.vUnCom;
        cadastro.qCom                   = action.qCom;
        cadastro.id_produto_unidade     = action.id_produto_unidade;
        cadastro.id_fornecedor          = action.id_fornecedor;
        cadastro.nome_fornecedor        = action.nome_fornecedor;
        return {...state,cadastro}
    }

    if(action.type === 'PRODUTOS.TOOGLE_CLEAN'){
        cadastro.refresh                = action.refresh;
        cadastro.id                     = 0;
        cadastro.id_produto             = 0;
        cadastro.cProd                  = '';
        cadastro.xProd                  = '';
        cadastro.vUnCom                 = '';
        cadastro.qCom                   = '';
        cadastro.id_produto_unidade     = '';
        cadastro.id_fornecedor          = '';
        cadastro.nome_fornecedor        = '';
        return {...state,cadastro}
    }

    //if(action.type === 'PRODUTOS.TOOGLE_FORNECEDOR_AUTO'){
    //    cadastro.id_fornecedor     = action.id;
    //    return {...state,cadastro}
    //}
    
    return state;    
}