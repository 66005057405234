const INITIAL_STATE = {
    grids:  
    {
        header      : ['ID','CPF/CNPJ','Nome','Telefone','E-mail'],
        colunas     : ['id_fornecedor','forn_cnpj','forn_razao','forn_tel1','forn_email'],
        checkbox    : '',
        styles      : ['50px','','','',''],
        lista       : [],
  
        limit       : 25,
        offset      : 1,
        pages       : 0,
        fNome       : '',
        endereco    : null,
        nav         :{
          tabs          :['Lista de Fornecedores','Cadastrar','Endereço'],
          className     :['lista active','cadastro','endereco disabled'],
          disabled      :[null,null,'disabled'],
          href          :['#lista','#cadastro','#endereco'],
          onClick       :[null,null,null]
        },
        edit        :{
          itens     :['Alterar Cadastro','Alterar Endereços'],
          onClick2   :['','']
        },
        onClickModal: '',
        id          : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form, 4 = lista endereços, 5 = busca fornecedor
        refresh     : 0
    },
    cadastro:
    {
        refresh         : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id              : 0,
        sIdFornecedor      : '',
        sStatus         : '',
        sCnpj           : '',
        sFornRazao       : '',
        sFornFantasia    : '',
        sFornTel1        : '',
        sFornCel1        : '',
        sFornEmail       : ''      
    },
    enderecos:
    {
        refresh     : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        header      : ['ID','Tipo','Principal?','CEP','Endereço','Bairro','Cidade','Estado'],
        colunas     : ['id_fornecedor_end','tp_end','forn_principal','forn_cep','forn_endereco','forn_bairro','cidade','estado'],
        checkbox    : '',
        styles      : ['50px','50px','50px','50px','','','','50px'],
        lista       : [],
        fornecedor  : '',
        limit       : 25,
        offset      : 1,
        pages       : 0,
        btnCadastro    : null,
        onClickModal: '',
        id_fornecedor       : 0,
        id_fornecedor_end   : 0
    }

}

export default function fornecedores(state = INITIAL_STATE, action){
    var grid            = state.grids;
    if(action.type === 'FORNECEDORES.TOOGLE_LISTA'){
        grid.erro           = action.erro;
        if(grid.erro===0){
            grid.lista              = action.lista;
            grid.onClickModal       = action.onClickModal;
            grid.callBackPaginacao  = action.callBackPaginacao;
            grid.pages              = action.pages;
            grid.limit              = action.limit;
            grid.offset             = action.offset;
            grid.edit.onClick       = action.editOnClick;
        }
        return {...state,grid}
    }

    if(action.type === 'FORNECEDORES.TOOGLE_NAV_ENDERECO'){
        grid.refresh            = action.refresh;
        grid.nav                = action.nav;
        grid.endereco           = action.endereco;
        return {...state,grid}
    }
    
    if(action.type === 'FORNECEDORES.TOOGLE_FNOME'){
        grid.fNome          = action.fNome;
        return {...state,grid}
    }
    if(action.type === 'FORNECEDORES.TOOGLE_LIMIT'){
        grid.offset         = 1;
        grid.limit          = action.limit;
        return {...state,grid}
    }
    if(action.type === 'FORNECEDORES.TOOGLE_OFFSET'){
        grid.offset         = action.offset;
        return {...state,grid}
    }
    
    var cadastro        = state.cadastro;
    if(action.type === 'FORNECEDORES.TOOGLE_CADASTRO'){
        cadastro.id         = action.id;
        cadastro.refresh    = 2;
        return {...state,cadastro}
    }
    
    if(action.type === 'FORNECEDORES.TOOGLE_REFRESH'){
        cadastro.refresh    = 0;
        return {...state,cadastro}
    }

    if(action.type === 'FORNECEDORES.TOOGLE_GET_ID'){
        cadastro.refresh              = 0;
        cadastro.sStatus              = action.sStatus;
        cadastro.sIdFornecedor           = action.sIdFornecedor;
        cadastro.sCnpj                = action.sCnpj;
        cadastro.sFornRazao            = action.sFornRazao;
        cadastro.sFornFantasia         = action.sFornFantasia;
        cadastro.sFornTel1             = action.sFornTel1;
        cadastro.sFornCel1             = action.sFornCel1;
        cadastro.sFornEmail            = action.sFornEmail;
        return {...state,cadastro}
    }

    if(action.type === 'FORNECEDORES.TOOGLE_CLEAN'){
        cadastro.refresh              = action.refresh;
        cadastro.id                   = 0;
        cadastro.sStatus              = '';
        cadastro.sIdFornecedor           = '';
        cadastro.sCnpj                = '';
        cadastro.sFornRazao            = '';
        cadastro.sFornFantasia         = '';
        cadastro.sFornTel1             = '';
        cadastro.sFornCel1             = '';
        cadastro.sFornEmail            = '';
        return {...state,cadastro}
    }

    var enderecos        = state.enderecos;
    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_ID_FORNECEDOR'){
        enderecos.refresh          = 4;
        enderecos.id_fornecedor       = action.id_fornecedor;
        return {...state,enderecos}
    }
    
    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_REFRESH'){
        enderecos.refresh = 0;
        grid.refresh = 0;
        return {...state,enderecos,grid}
    }

    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_ID_FORNECEDOR_ENDERECO'){
        enderecos.refresh           = action.refresh;
        enderecos.id_fornecedor_end    = action.id_fornecedor_end;
        enderecos.btnCadastro       = action.btnCadastro;
        return {...state,enderecos}
    }

    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_LISTA'){
        enderecos.erro           = action.erro;
        if(enderecos.erro===0){
            enderecos.fornecedor          = action.fornecedor;
            enderecos.lista              = action.lista;
            enderecos.btnCadastro        = action.btnCadastro;
            enderecos.onClickModal       = action.onClickModal;
            enderecos.callBackPaginacao  = action.callBackPaginacao;
            enderecos.pages              = action.pages;
            enderecos.limit              = action.limit;
            enderecos.offset             = action.offset;
        }
        return {...state,enderecos}
    }
    
    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_LIMIT'){
        enderecos.offset         = 1;
        enderecos.limit          = action.limit;
        enderecos.refresh        = action.refresh;
        return {...state,enderecos}
    }
 
    if(action.type === 'FORNECEDORES.ENDERECO.TOOGLE_OFFSET'){
        enderecos.offset           = action.offset;
        enderecos.refresh          = action.refresh;
        return {...state,enderecos}
    }
 
    return state;    
}