import React, {Component} from 'react';
import store            from '../store';
import FormEdit         from '../components/FormEdit.js';
import Grid             from '../components/Grid.js';
import Paginacao        from '../components/Paginacao.js';

import {GetClientesEndAPI} from '../models/ModelClientesEnd.js';
import ClientesEnderecoUpdate from './ClientesEnderecoUpdate.js';

class ClientesEndereco extends Component{
    
  constructor(props) {
    super(props);
    this.state = store.getState().clientes.enderecos;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;
   
    document.body.appendChild(script);    
  }

	componentDidMount() {
    this.callApi(1)
  }
  
  componentDidUpdate(prevProps, prevState){

    var response = {};

    if(store.getState().clientes.enderecos.refresh===5 || 
    (store.getState().clientes.enderecos.refresh===4 &&
    store.getState().clientes.enderecos.id_cliente!==this.state.id_cliente)){
      response = {type: 'CLIENTES.ENDERECO.TOOGLE_REFRESH'}
      store.dispatch(response);
      this.callApi(1);   
    }

    if(this.state.refresh===2){
      response = {refresh       : 0, 
                  btnCadastro:  <ClientesEnderecoUpdate 
                                id_cliente              = {this.state.id_cliente} 
                                id_cliente_end          = {this.state.id_cliente_end} 
                                refresh                 = {2}
                                returnComponentRefresh  = {this.returnComponentRefresh}
                                />
                }

      this.setState(response);
      
    }

    if(this.state.refresh===3){
      this.callApi(0);
      response = {refresh             : 0, 
                  id_cliente          : this.state.id_cliente,
                  id_cliente_end      : 0,
                  btnCadastro         : this.btnCadastroNovo()}
      this.setState(response) ; 

    }

  }

  btnCadastroNovo = () =>{
     return (<><FormEdit  
                id = 'cadNovoEnd'  
                label      = 'Novo Cadastro'         
                styleDiv   = 'submit' 
                onClick    = {this.novoCadastro} 
                styleField = 'submit'
                typeField  = 'button' 
                icon       = 'adiciona' />
                
              <FormEdit  
                id = 'AtualizaEnd'  
                label      = 'Atualizar'         
                styleDiv   = 'submit' 
                onClick    = {() => this.callApi(1)} 
                styleField = 'submit'
                typeField  = 'button' 
                icon       = 'atualiza' />  
               </> )
  }

  
  returnComponentRefresh= ()=> {

    this.setState({
      refresh   : 3
    })

  }

  callBackPaginacao  = (response)=> {
    response.refresh = 5;
    response.type    = 'CLIENTES.ENDERECO.'+response.type;
    store.dispatch(response);
    this.setState(response);
  }

  async callApi(modal){
    var grids = store.getState().clientes.enderecos;
    var response = await GetClientesEndAPI(grids.id_cliente, grids.limit,grids.offset,modal);
    response.refresh = 0;      
    response.callBackPaginacao  = this.callBackPaginacao;
    response.btnCadastro        = this.btnCadastroNovo();
    response.onClickModal       = this.novoCadastro;
    response.type               = 'CLIENTES.ENDERECO.TOOGLE_LISTA';
    store.dispatch(response);
    grids = store.getState().clientes.enderecos;
    this.setState(grids) ;
  }

  handleSubmit = (event) => {
    event.preventDefault()    
  }
 

  novoCadastro = (event,id_cliente_end) => {
    event.preventDefault()

    this.setState({
      refresh        : 2,
      id_cliente_end : id_cliente_end
    }) ;
  
  }


	render(){  
    
  	return (
      <div>
        <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
          {<FormEdit id='sEndIdCliente' typeField='hidden' 
                    value={store.getState().clientes.enderecos.id_cliente} 
          />}

          {<FormEdit  id='sEndCnpj'        
                      label='Cliente'                
                      styleDiv='1'
                      value={store.getState().clientes.enderecos.cliente}
                      typeField='readonly' 
          />}
          
  
        </form>            
        {this.state.btnCadastro} 
        {Grid(store.getState().clientes.enderecos)}
        {Paginacao(store.getState().clientes.enderecos)}
       
      </div>
 
    );
      		
	}
}

export default ClientesEndereco;
