const INITIAL_STATE = {
    grids:  
    {
        header      : ['ID','CPF','Nome','Status'],
        colunas     : ['id_empresa_usu','emp_usu_cpf','emp_usu_nome','status'],
        checkbox    : '',
        styles      : ['50px','','',''],
        lista       : [],
  
        limit       : 25,
        offset      : 1,
        pages       : 0,
        fNome       : '',
        fid_sis_status: '',
        endereco    : null,
        nav         :{
          tabs          :['Lista de Colaboradores','Cadastrar'],
          className     :['lista active','cadastro'],
          disabled      :[null,null],
          href          :['#lista','#cadastro'],
          onClick       :[null,null]
        },
        edit        :{
          itens     :['Alterar Cadastro'],
          onClick   :['']
        },
        onClickModal: '',
        id          : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
        refresh     : 0
    },
    cadastro:
    {
        refresh             : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id                  : 0,
        id_empresa_usu      : '',
        emp_usu_nome        : '',
        emp_usu_cpf         : '',
        id_sis_status       : '',
        emp_usu_telefone    : '',
        emp_usu_ramal       : '',
        emp_usu_celular     : '',
        emp_usu_email       : ''
    }

}

export default function colaboradores(state = INITIAL_STATE, action){
    var grid            = state.grids;
    if(action.type === 'COLABORADORES.TOOGLE_LISTA'){
        grid.erro           = action.erro;
        if(grid.erro===0){
            grid.lista              = action.lista;
            grid.onClickModal       = action.onClickModal;
            grid.callBackPaginacao  = action.callBackPaginacao;
            grid.pages              = action.pages;
            grid.limit              = action.limit;
            grid.offset             = action.offset;
            grid.edit.onClick       = action.editOnClick;
        }
        return {...state,grid}
    }

    if(action.type === 'COLABORADORES.TOOGLE_FNOME'){
        grid.fNome          = action.fNome;
        return {...state,grid}
    }
    
    if(action.type === 'COLABORADORES.TOOGLE_LIMIT'){
        grid.offset         = 1;
        grid.limit          = action.limit;
        return {...state,grid}
    }
    
    if(action.type === 'COLABORADORES.TOOGLE_OFFSET'){
        grid.offset         = action.offset;
        return {...state,grid}
    }
    
    var cadastro        = state.cadastro;
    if(action.type === 'COLABORADORES.TOOGLE_CADASTRO'){
        cadastro.id         = action.id;
        cadastro.refresh    = 2;
        return {...state,cadastro}
    }
    
    if(action.type === 'COLABORADORES.TOOGLE_REFRESH'){
        cadastro.refresh    = 0;
        return {...state,cadastro}
    }

    if(action.type === 'COLABORADORES.TOOGLE_GET_ID'){
        cadastro.refresh                = 0;
        cadastro.id_empresa_usu         = action.id_empresa_usu;
        cadastro.id_sis_status          = action.id_sis_status;
        cadastro.emp_usu_nome           = action.emp_usu_nome;
        cadastro.emp_usu_cpf            = action.emp_usu_cpf;
        cadastro.emp_usu_email          = action.emp_usu_email;
        cadastro.emp_usu_telefone       = action.emp_usu_telefone;
        cadastro.emp_usu_ramal          = action.emp_usu_ramal;
        cadastro.emp_usu_celular        = action.emp_usu_celular;
        return {...state,cadastro}
    }

    if(action.type === 'COLABORADORES.TOOGLE_CLEAN'){
        cadastro.refresh                = action.refresh;
        cadastro.id                     = 0;
        cadastro.id_empresa_usu         = 0;
        cadastro.id_sis_status          = '';
        cadastro.emp_usu_nome           = '';
        cadastro.emp_usu_cpf            = '';
        cadastro.emp_usu_email          = '';
        cadastro.emp_usu_telefone       = '';
        cadastro.emp_usu_ramal          = '';
        cadastro.emp_usu_celular        = '';
        return {...state,cadastro}
    }
   
    return state;    
}