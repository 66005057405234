const INITIAL_STATE = {
    grids:  
    {
        header      : ['ID','Serviço','Status'],
        colunas     : ['id_servico','serv_nome','status'],
        checkbox    : '',
        styles      : ['50px','',''],
        lista       : [],
  
        limit       : 25,
        offset      : 1,
        pages       : 0,
        fNome       : '',
        fid_sis_status  : '',
        endereco    : null,
        nav         :{
          tabs          :['Lista de Serviços','Cadastrar'],
          className     :['lista active','cadastro'],
          disabled      :[null,null],
          href          :['#lista','#cadastro'],
          onClick       :[null,null,null]
        },
        edit        :{
          itens     :['Alterar Cadastro'],
          onClick   :['']
        },
        onClickModal: '',
        id          : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
        refresh     : 0
    },
    cadastro:
    {
        refresh             : 0, //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id                  : 0,
        id_servico          : '',
        serv_nome           : '',
        serv_descricao      : '',
        serv_valor          : '',
        serv_duracao        : '',
        id_sis_status       : ''
    }

}

export default function servicos(state = INITIAL_STATE, action){
    var grid            = state.grids;
    if(action.type === 'SERVICOS.TOOGLE_LISTA'){
        grid.erro           = action.erro;
        if(grid.erro===0){
            grid.lista              = action.lista;
            grid.onClickModal       = action.onClickModal;
            grid.callBackPaginacao  = action.callBackPaginacao;
            grid.pages              = action.pages;
            grid.limit              = action.limit;
            grid.offset             = action.offset;
            grid.edit.onClick       = action.editOnClick;
        }
        return {...state,grid}
    }

    if(action.type === 'SERVICOS.TOOGLE_FNOME'){
        grid.fNome          = action.fNome;
        return {...state,grid}
    }

    if(action.type === 'SERVICOS.TOOGLE_FSTATUS'){
        grid.fid_sis_status          = action.fid_sis_status;
        return {...state,grid}
    }

    if(action.type === 'SERVICOS.TOOGLE_LIMIT'){
        grid.offset         = 1;
        grid.limit          = action.limit;
        return {...state,grid}
    }
    
    if(action.type === 'SERVICOS.TOOGLE_OFFSET'){
        grid.offset         = action.offset;
        return {...state,grid}
    }
    
    var cadastro        = state.cadastro;
    if(action.type === 'SERVICOS.TOOGLE_CADASTRO'){
        cadastro.id         = action.id;
        cadastro.refresh    = 2;
        return {...state,cadastro}
    }
    
    if(action.type === 'SERVICOS.TOOGLE_REFRESH'){
        cadastro.refresh    = 0;
        return {...state,cadastro}
    }

    if(action.type === 'SERVICOS.TOOGLE_GET_ID'){
        cadastro.refresh                = 0;
        cadastro.id_servico             = action.id_servico;
        cadastro.serv_nome              = action.serv_nome;
        cadastro.serv_descricao         = action.serv_descricao;
        cadastro.serv_valor             = action.serv_valor;
        cadastro.serv_duracao           = action.serv_duracao;
        cadastro.id_sis_status          = action.id_sis_status;
        cadastro.status                 = action.status;
        return {...state,cadastro}
    }

    if(action.type === 'SERVICOS.TOOGLE_CLEAN'){
        cadastro.refresh                = action.refresh;
        cadastro.id                     = 0;
        cadastro.id_servico             = 0;
        cadastro.serv_nome              = '';
        cadastro.serv_descricao         = '';
        cadastro.serv_valor             = '';
        cadastro.serv_duracao           = '';
        cadastro.id_sis_status          = '';
        return {...state,cadastro}
    }

    
    return state;    
}   