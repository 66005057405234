import React, { Component } from 'react';
import store from '../store';
import { getSisStatus, getFormasPgto } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetFornecedoresAutocompleteAPI } from '../models/ModelFornecedores.js';
import { GetContasPagarIdAPI, PostContasPagarAPI } from '../models/ModelContasPagar.js';

class ContasPagarUpdate extends Component {

  constructor(props) {
    super(props);
    this.state = store.getState().contasPagar.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 1) {
      this.callApi();
      this.setState({
        refresh: 0
      })
    }

    var response = {}
    if (store.getState().contasPagar.cadastro.refresh === 2 &&
      store.getState().contasPagar.cadastro.id !== store.getState().contasPagar.cadastro.id_contas_pagar
    ) {
      response = { type: 'CONTASPAGAR.TOOGLE_REFRESH' }
      store.dispatch(response);
      this.callApiGetContasPagar(store.getState().contasPagar.cadastro.id)
    }

    if (store.getState().contasPagar.cadastro.refresh === 3) {
      response = { refresh: 0, type: 'CONTASPAGAR.TOOGLE_CLEAN' }
      store.dispatch(response);
      var cadastro = store.getState().contasPagar.cadastro;
      this.setState(cadastro);
    }

  }

  async callApi() {

    let data =
    {
      id_contas_pagar_pai: this.state.id_contas_pagar_pai,
      id_fornecedor: this.state.id_fornecedor,
      id_sis_forma_pgto: this.state.id_sis_forma_pgto,
      id_sis_status: this.state.id_sis_status,
      conta_pag_parcelas: this.state.conta_pag_parcelas,
      conta_pag_parcela_num: this.state.conta_pag_parcela_num,
      conta_pag_nf: this.state.conta_pag_nf,
      conta_pag_cod_barra: this.state.conta_pag_cod_barra,
      conta_pag_descricao: this.state.conta_pag_descricao,
      conta_pag_valor_total: this.state.conta_pag_valor_total,
      conta_pag_valor_pago: this.state.conta_pag_valor_pago,
      conta_pag_valor_desc: this.state.conta_pag_valor_desc,
      conta_pag_valor_juros: this.state.conta_pag_valor_juros,
      conta_pag_data_venc: this.state.conta_pag_data_venc,
      conta_pag_data_pgto: this.state.conta_pag_data_pgto
    }

    var response = await PostContasPagarAPI(this.state.id_contas_pagar, data);

    if (this.state.id_contas_pagar === 0 && response.erro === 0) {
      response.type = 'CONTAPAGAR.TOOGLE_CLEAN';
      store.dispatch(response);
      response = store.getState().contasPagar.cadastro;
    }
    this.setState(response);


  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  handleInputChangeAuto = (values) => {
    if (values === null)
      this.setState({
        id_fornecedor: '',
        nome_fornecedor: ''
      });
    else
      this.setState({
        id_fornecedor: values.id,
        nome_fornecedor: values.nome
      });

  }

  async loadData(nome) {
    var response = await GetFornecedoresAutocompleteAPI(nome);
    if (response.erro === 0)
      return response.lista;
    else
      return [{}];

  }
  async callApiGetContasPagar(id) {

    var response = await GetContasPagarIdAPI(id);

    response.refresh = 0;
    if (response.erro === 0)
      response.type = 'CONTASPAGAR.TOOGLE_GET_ID';
    else
      response.type = 'CONTASPAGAR.TOOGLE_CLEAN';
    store.dispatch(response);
    var cadastro = store.getState().contasPagar.cadastro;
    this.setState(cadastro);

  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 1
    });

  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  render() {
    return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_fornecedor'
          label='ID Fornecedor'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.id_fornecedor}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='nome_fornecedor'
          label='Nome Fornecedor'
          styleDiv='2'
          typeField='autocomplete'
          onChange={this.handleInputChangeAuto}
          loadData={this.loadData}
          options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
          value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}

          required='on'
        />}

        {<FormEdit id='id_sis_forma_pgto'
          label='Forma de pagamento'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_forma_pgto}
          required='on'
          optionsField={getFormasPgto('forma_pgto_conta_pagar')}
        />}

        {<FormEdit id='conta_pag_parcelas'
          label='Qtde de parcelas'
          styleDiv='2'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_parcelas}
        />}

        {<FormEdit id='conta_pag_parcela_num'
          label='Número da parcela'
          styleDiv='2'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_parcela_num}
        />}

        {<FormEdit id='conta_pag_nf'
          label='Nota fiscal'
          styleDiv='2'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_nf}
        />}

        {<FormEdit id='conta_pag_cod_barra'
          label='Código de Barras'
          styleDiv='2'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_cod_barra}
        />}

        {<FormEdit id='conta_pag_descricao'
          label='Descrição'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_descricao}
          required='on'
        />}

        {<FormEdit id='conta_pag_valor_total'
          label='Valor total'
          styleDiv='2'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_total}
          required='on'
        />}

        {<FormEdit id='conta_pag_valor_desc'
          label='Desconto'
          styleDiv='2'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_desc}
        />}

        {<FormEdit id='conta_pag_valor_juros'
          label='Valor juros'
          styleDiv='2'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_juros}
        />}

        {<FormEdit id='conta_pag_valor_pago'
          label='Valor Pago'
          styleDiv='2'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_pago}
        />}

        {<FormEdit id='conta_pag_data_venc'
          label='Dt. Vencimento'
          styleDiv='2'
          mask="date"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_data_venc}
          required='on'
        />}

        {<FormEdit id='conta_pag_data_pgto'
          label='Dt. Pagamento'
          mask="date"
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_data_pgto}
        />}

        {<FormEdit id='id_sis_status'
          label='Status'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_status}
          required='on'
          optionsField={getSisStatus('status_contas_pag')}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}


export default ContasPagarUpdate;
