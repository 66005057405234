import React, {Component} from 'react';
import store              from '../store';
import {getSisStatus}     from '../services/Auth.js';
import FormEdit           from '../components/FormEdit.js';

import { GetServicoAPI, PostServicoAPI } from '../models/ModelServicos.js';

class ServicosUpdate extends Component{
    
  constructor(props) {
    super(props);
    this.state = store.getState().servicos.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    
    document.body.appendChild(script);    

  }
  
  componentDidUpdate(prevProps, prevState){
  
    if(this.state.refresh===1){
       this.callApi();
       this.setState({
        refresh   : 0
      })  
    } 
    
    var response = {}
    if(store.getState().servicos.cadastro.refresh===2 && 
    store.getState().servicos.cadastro.id!==store.getState().servicos.cadastro.id_servico
    ){
      response = {type: 'SERVICOS.TOOGLE_REFRESH'}
      store.dispatch(response);
      this.callApiGetServico(store.getState().servicos.cadastro.id)      
    }

    if(store.getState().servicos.cadastro.refresh===3){
      response = {refresh:0, type: 'SERVICOS.TOOGLE_CLEAN'}
      store.dispatch(response);
      var cadastro = store.getState().servicos.cadastro;
      this.setState(cadastro) ; 
    }

  }

  async callApi(){

    let data =
    {
      id_sis_status:        this.state.id_sis_status,
      serv_nome:            this.state.serv_nome,
      serv_descricao:       this.state.serv_descricao,
      serv_duracao:         this.state.serv_duracao,
      serv_valor:           this.state.serv_valor
    }

    var response = await PostServicoAPI(this.state.id_servico,data);
   
    if(this.state.id_servico===0 && response.erro===0){ 
      response.type   = 'SERVICOS.TOOGLE_CLEAN';
      store.dispatch(response);
      response = store.getState().servicos.cadastro;
    }
    this.setState(response); 

    
  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  async callApiGetServico(id){
    
    var response = await GetServicoAPI(id);
    
    response.refresh  = 0;     
    if(response.erro===0) 
      response.type   = 'SERVICOS.TOOGLE_GET_ID';
    else
      response.type   = 'SERVICOS.TOOGLE_CLEAN';
    store.dispatch(response);
    var cadastro = store.getState().servicos.cadastro;
    this.setState(cadastro);  
    
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 1
    }) ;
    
  }
 
  handleInputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  render(){  
  	return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
       
        {<FormEdit  id='serv_nome'           
                    label='Serviço'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.serv_nome}
                    required='on'
        />}

        {<FormEdit  id='serv_descricao'           
                    label='Descrição do serviço'                
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    value={this.state.serv_descricao}
                    required='on'
        />}

        {<FormEdit  id='serv_duracao'           
                    label='Duração'                
                    styleDiv='2'
                    mask="time" 
                    onChange={this.handleInputChange} 
                    value={this.state.serv_duracao}
        />}

        {<FormEdit  id='serv_valor'           
                    label='Valor'                
                    styleDiv='2' 
                    mask="real"
                    onChange={this.handleInputChange} 
                    value={this.state.serv_valor}
        />}

        {<FormEdit  id='id_sis_status'         
                    label='Status'                  
                    styleDiv='2' 
                    onChange={this.handleInputChange} 
                    typeField='select'
                    value={this.state.id_sis_status}   
                    required='on'
                    optionsField={getSisStatus('status_padrao')}
        />}

        {<FormEdit  id         = 'gravar'  
                    label      = 'Gravar'         
                    styleDiv   = 'submit right'
                    styleField = 'submit'
                    typeField  = 'submit'   
                    icon       = 'gravar'         
                  />}
      </form>                          

    );
      		
	}
}


export default ServicosUpdate;
