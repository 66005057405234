import React from 'react';
import ReactDOM from 'react-dom';
import api from '../services/Api.js';
import Modal from '../components/Modal.js';

/**
 * Autocomplete de Produtos
 * @param {*} fIdFornecedor 
 * @param {*} fNome 
 */

export const GetProdutosAutocompleteAPI = (fIdFornecedor, fNome) => {
  return api.get('/produto/autocomplete?fid_fornecedor='+fIdFornecedor+'&fnome='+fNome)
  .then(response => {
    return {
      lista     : response.data.result.lista, 
      erro      : 0      
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })
}

/**
 * Lista de Produtos
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetProdutosAPI = (limit, offset,fNome) => {
  
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));   
  

  return api.get('/produto/lista?limit='+limit+'&offset='+offset+'&fnome='+fNome)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));   
    return {
      lista     : response.data.result.lista, 
      pages     : response.data.result.pages,
      limit     : response.data.result.limit,
      offset    : response.data.result.offset,
      erro      : 0
      
    }
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;
    ReactDOM.render (<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));
    return {
      error     : 1
    }


  })

        		
	
}


/* 
 * Busca Produto
 * @param {*} id  
 */
export const GetProdutoAPI = (id) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     
  return api.get('/produto/'+id)
  .then(response => {
    ReactDOM.render(<Modal type="clear" />,document.getElementById('sys-modal'));
    var ret         = response.data.result;
    ret.id          = id;
    ret.id_produto  = id;
    ret.refresh     = 0;
    ret.erro        = 0; 
    return ret
  })
  .catch(error => {
    var errorCatch = 'Erro de comunicação com o servidor';
    if (error.response !== undefined)
        errorCatch = error.response.data.error;

    ReactDOM.render(<Modal  type="warning" 
                            button="Fechar" 
                            title="Atenção!" 
                            content={errorCatch}
                            />
                            ,document.getElementById('sys-modal'));

    return {
      erro: 1
    }

  })

}

/* 
 * Cadastro do Produto
 * @param {*} id 
 * @param {*} data 
 */
export const PostProdutoAPI = (id,data) => {
  ReactDOM.render(<Modal type="progress" />,document.getElementById('sys-modal'));     

  var url = '';
  if(id===0){
    url = '/produto/add';
  }else{
    url = '/produto/'+id;
  }

  return api.post(url,data)
    .then(response => {
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={response.data.result.mensagem}/>
                              ,document.getElementById('sys-modal'));

      return {
        erro: 0,
        refresh: 0
      }
                        

      })
    .catch(error => {
      var errorCatch = 'Erro de comunicação com o servidor';
      if (error.response !== undefined)
          errorCatch = error.response.data.error;
      ReactDOM.render(<Modal  type="warning" 
                              button="Fechar" 
                              title="Atenção!" 
                              content={errorCatch}/>
                              ,document.getElementById('sys-modal'));
      return {
        erro: 1
      }
    })
  
}
