import React, {Component} from 'react';

class NavBar extends Component{
  
  constructor(props) {
    super(props);

    this.state = {
      tabs:      this.props.tabs,
      className: this.props.className,
      onClick:   this.props.onClick,
      href:      this.props.href
    }

  }

  componentDidUpdate(prevProps, prevState){
  }
  



  handleChange = (e) => {
    this.props.onChange(e);
  }

  montaTabs = () => {
    if(this.state.tabs.length>0){
        return this.state.tabs.map((item,keys) =>
          <li key={'tab'+keys} className={'tab '+this.props.disabled[keys]}>
            <a href={this.state.href[keys]} className={this.state.className[keys]} onClick={this.props.onClick[keys]}>{item}</a>
          </li>
        );
      }else{
        return '';
      }
  } 

  render() {
    
    return (
      <div className="nav-content">
        <ul className="tabs">
          {this.montaTabs()}
          <li className="indicator"></li>
        </ul>
      </div>            
      
    );
          
  }

}

export default NavBar;
